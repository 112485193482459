const ContinueButton = ({
  enabled,
  onClick = () => {},
  text = 'Continue',
  size = 'medium',
  extraClasses = null,
}: any) => {
  const buttonStyles = [
    'action-button',
    enabled ? 'enabled' : 'disabled',
    size,
    extraClasses,
  ].join(' ')

  return (
    <div className={['action-button-wrap', size].join(' ')}>
      <input
        type="submit"
        onClick={onClick}
        className={buttonStyles}
        value={text}
        disabled={!enabled}
      />
    </div>
  )
}

export default ContinueButton

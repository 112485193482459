import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Layout from '../layouts/Layout'
import store from '../redux/store'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { FacebookPixel } from '../components/FacebookPixel'
import EligibilityGuard from '@components/EligiblityGuard'

import { SegmentTracker } from '@components/SegmentTracker'
import { MixpanelTracker } from '@components/MixpanelTracker'
import GoogleAnalytics from '@components/GoogleAnalytics'
import DatadogRum from '@components/DatadogRum'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter()

  const setBodyClass = (className: string) => {
    const body = window.document.querySelector('body')
    if (typeof window !== 'undefined' && body !== null) {
      body.className = className
    }
  }

  useEffect(() => {
    if (
      asPath.includes('quiz') ||
      asPath.includes('results') ||
      asPath.includes('restore') ||
      asPath.includes('thanks')
    ) {
      setBodyClass('quiz')
    } else if (asPath.includes('order')) {
      setBodyClass('order')
    } else {
      setBodyClass('')
    }
  }, [asPath])

  return (
    <Provider store={store}>
      <Head>
        <title>Measured - Modern Weight Loss</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png?original"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png?original"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png?original"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
        <meta
          name="description"
          content="Measured is the easiest way to get started with the Ketogenic diet. We create personalized meal-plans based on your information and dietary preferences with delicious recipes that use fresh ingredients."
        />
        <meta
          name="keywords"
          content="keto diet,keto,keto meal plan,meal plans,fitness,health and wellness"
        />
      </Head>
      <div>
        {process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && <GoogleAnalytics />}
        {process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID && <FacebookPixel />}
        {process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY && <SegmentTracker />}
        {process.env.NEXT_PUBLIC_MIXPANEL_API_KEY && <MixpanelTracker />}
        {process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN && <DatadogRum />}
        <Layout>
          <Sentry.ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
            <EligibilityGuard>
              <Component {...pageProps} />
            </EligibilityGuard>
          </Sentry.ErrorBoundary>
        </Layout>
      </div>
    </Provider>
  )
}

export default MyApp

import React from "react";
import ActionButton from "./action-button";
import classNames from "classnames";

interface ActionsProps {
  nextStep?: number
  previousStep?: number
  nextEnabled: boolean
  isLastStep: boolean
  onNext?: () => void
}

const Actions: React.FC<ActionsProps> = ({ onNext, isLastStep, nextEnabled, nextStep, previousStep }) => {
  const PreviousButton = <ActionButton type="button" enabled={true} href={`/quiz/${previousStep}`}>Previous</ActionButton>

  const NextButton = <ActionButton onClick={onNext} enabled={nextEnabled} type="submit" className="next-button">Next &rarr;</ActionButton>

  const FinishButton = <ActionButton type="submit" onClick={onNext} enabled={nextEnabled} className="next-button">Finish</ActionButton>

  return (
    <div className="action-container">
      {/* {previousStep && PreviousButton} */}
      {nextStep && NextButton}
      {isLastStep && FinishButton}
    </div>
  )
}

export default Actions;

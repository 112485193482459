import StepLabel from '@components/quiz/step-label'
import StepHint from '@components/quiz/step-hint'
import StepPretext from '@components/quiz/step-pretext'
import React, { useMemo, useState, useEffect } from 'react'
import { StepChangeHandler, QuizProps, QuizStepType } from '@types'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectStepValue, setStepValue } from 'redux/quiz'
import { selectName, setStartTime } from 'redux/user'
import { components, getQuestionDefaultValue } from './questions'
import Router, { useRouter } from 'next/router'
import { setQuizProgress } from 'redux/quiz/steps'
import { trackMixpanelEvent } from '@components/MixpanelTracker'

const Quiz: React.FC<QuizProps> = props => {
  const { step } = props
  const stepValue = useAppSelector(state => selectStepValue(state, step.id))
  const name = useAppSelector(selectName)
  const dispatch = useAppDispatch()
  const { asPath } = useRouter()

  const defaultValue = useMemo(() => getQuestionDefaultValue(step), [step])
  const [value, setValue] = useState(stepValue || defaultValue)
  const StepComponent = useMemo(() => components[step.type], [step.type])

  const replaceName = (value: string) =>
    name ? value.replace(/\[name\]/g, name) : value

  useEffect(() => {
    trackMixpanelEvent(`Viewed Quiz Step ${props.currentStep}`, {
      id: props?.step?.id,
      label: props?.step?.label,
    })
  }, [props.currentStep, props?.step?.label, props?.step?.id])

  useEffect(() => {
    setValue(stepValue || defaultValue)
    dispatch(setQuizProgress(props))
  }, [stepValue, defaultValue, setValue, dispatch, props])

  useEffect(() => {
    if (
      asPath === '/' ||
      asPath === '/quiz/1' ||
      asPath === '/quiz/start-female' ||
      asPath === '/quiz/start-male'
    ) {
      dispatch(setStartTime())
    }
  }, [asPath])

  const changeHandler: StepChangeHandler<unknown> = (
    name: string,
    value: unknown,
  ) => {
    setValue(value)

    // TODO: Refactor, there's probably a much cleaner way to do this
    if (step.type === QuizStepType.SINGLE_CHOICE) {
      dispatch(setStepValue({ stepId: step.id, value }))
      Router.push(
        props.isLastStep ? `/quiz/processing` : `/quiz/${props.nextStep}`,
      )
    }
  }

  const submitHandler = (e: React.SyntheticEvent<Element, Event> | null) => {
    if (e) e.preventDefault()

    dispatch(setStepValue({ stepId: step.id, value }))

    trackMixpanelEvent(`Answered Quiz Step ${props.currentStep}`, {
      id: props?.step?.id,
      label: props?.step?.label,
      value,
    })

    Router.push(
      props.isLastStep ? `/quiz/processing` : `/quiz/${props.nextStep}`,
    )
  }

  return (
    <div className={['quiz-step fade-in', step.id, step.type].join(' ')}>
      <div className="quiz-header">
        {step.pretext && <StepPretext text={replaceName(step.pretext)} />}
        {step.label && <StepLabel label={replaceName(step.label)} />}
        {step.hint && <StepHint hint={replaceName(step.hint)} />}
      </div>
      <form className="quiz-content" onSubmit={submitHandler}>
        <StepComponent
          {...props}
          key={step.id}
          value={value}
          step={step}
          onChange={changeHandler}
        />
      </form>
    </div>
  )
}

export default Quiz

import { Plan, Track } from '../@types'

const production = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export const behaviorPlans: Plan[] = [
  {
    id: 'N1-MO-C',
    sku: 'N1-MO-C',
    duration: 1,
    amount: 2600,
    name: '1 Month Plan',
    line_item: 'Measured Nutrition - 1 Month',
    billed: 'monthly',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1LPb7bBS2APHTgAqGp1XUCKq'
      : 'price_1LPb5UBS2APHTgAq8TyCEPGi',
    deal: null,
    billed_long: 'Billed $26 every month',
    originalPrice: 26,
    salePrice: 26,
    productName: '1 Month Plan',
    productNameLong: '1 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: null,
    track: Track.BEHAVIORAL,
  },
  {
    id: 'N3-MO-C',
    sku: 'N3-MO-C',
    duration: 3,
    originalPrice: 78,
    salePrice: 36,
    amount: 3600,
    name: '3 Month Plan',
    line_item: 'Measured Nutrition - 3 Months',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1LPb7bBS2APHTgAqsabaiDvN'
      : 'price_1LPb5yBS2APHTgAqZer6Qlj7',
    deal: 'Limited Time Offer',
    billed_long: 'Billed $36 every 3 months',
    productName: '3 Month Plan',
    productNameLong: '3 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: 'Special',
    showBonus: false,
    bonus: null,
    offers: null,
    track: Track.BEHAVIORAL,
  },
  {
    id: 'N12-MO-C',
    sku: 'N12-MO-C',
    duration: 12,
    originalPrice: 312,
    salePrice: 100,
    amount: 10000,
    name: '12 Month Plan',
    line_item: 'Measured Nutrition - 12 Months',
    billed: 'annually',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1LPb7bBS2APHTgAqg8zcyXBK'
      : 'price_1LPb7IBS2APHTgAqqQ5dTndW',
    deal: 'Best Deal',
    billed_long: 'Billed $100 every year',
    productName: '12 Month Plan',
    productNameLong: '12 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: true,
    showBonus: true,
    bonus: 'waterbottle',
    showSpecialOffer: 'Best Deal',
    offers: [
      'Limited Edition Water Bottle',
    ],
    track: Track.BEHAVIORAL,
  },
]
export const medicalPlans: Plan[] = [
  {
    id: 'N1-MO-RX',
    sku: 'N1-MO-RX',
    duration: 1,
    amount: 2600,
    name: '1 Month Plan',
    line_item: 'Measured Rx - 1 Month',
    billed: 'monthly',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1LPbBABS2APHTgAqQVqwR1Cn'
      : 'price_1LPb97BS2APHTgAqtxdQj3AF',
    deal: null,
    billed_long: 'Billed every month',
    originalPrice: 26,
    salePrice: 26,
    productName: '1 Month Plan (Rx)',
    productNameLong: '1 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: null,
    track: Track.MEDICAL,
  },
  {
    id: 'N3-MO-RX',
    duration: 3,
    originalPrice: 78,
    salePrice: 36,
    amount: 3600,
    name: '3 Month Plan',
    line_item: 'Measured Rx - 3 Months',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1LPbBABS2APHTgAq0rbdk4PZ'
      : 'price_1LPb9YBS2APHTgAqF6bpG9iC',
    deal: 'Limited Time Offer',
    billed_long: 'Billed every 3 months',
    sku: 'N3-MO-RX',
    productName: '3 Month Plan (Rx)',
    productNameLong: '3 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: 'Special',
    showBonus: false,
    bonus: null,
    offers: null,
    track: Track.MEDICAL,
  },
  {
    id: '12-MO-RX',
    duration: 12,
    originalPrice: 312,
    salePrice: 100,
    amount: 10000,
    name: '12 Month Plan',
    line_item: 'Measured Rx - 12 Months',
    billed: 'annually',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1LPbBABS2APHTgAqxidEdJhQ'
      : 'price_1LPbB1BS2APHTgAq5CBKu4gf',
    deal: 'Best Deal',
    billed_long: 'Billed every 12 months',
    sku: '12-MO-RX',
    productName: '12 Month Plan (Rx)',
    productNameLong: '12 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: true,
    showSpecialOffer: 'Best Deal',
    showBonus: true,
    bonus: 'waterbottle',
    offers: [
      'Limited Edition Water Bottle',
    ],
    track: Track.MEDICAL,
  },
]

const plans = { medicalPlans, behaviorPlans }

export const defaultPlan = (track: Track) =>
  track === 'medical' ? plans.medicalPlans[2] : plans.behaviorPlans[2]

export default plans

import { useEffect } from "react";
import TagManager from 'react-gtm-module';
interface GoogleAnalyticsProps { }

const GoogleAnalytics: React.FunctionComponent<GoogleAnalyticsProps> = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
    }

    TagManager.initialize(tagManagerArgs);
  }, []);

  return null;
}

export default GoogleAnalytics;
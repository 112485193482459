import { useRouter } from "next/router";
import React from "react";


export const SegmentTracker = function () {
  const router = useRouter();

  React.useEffect(() => {
    window.analytics.page(router.asPath);
    router.events.on("routeChangeComplete", (url) => {
      window.analytics.page(url);
    });
  }, [router.asPath, router.events])
  return null;
};

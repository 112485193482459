import React from 'react'
import { GetStaticPaths, GetStaticProps } from 'next'
import { ParsedUrlQuery } from 'querystring'
import { QuizSteps, QuizProps } from '@types'
import Quiz from '@components/quiz'
import { quiz as quizData } from 'data/quiz'

interface Params extends ParsedUrlQuery {
  step: string
}

const QuizPage: React.FC<QuizProps> = props => {
  return <Quiz {...props} />
}

export const getStaticPaths: GetStaticPaths = async () => {
  const quiz: QuizSteps[] = quizData
  const paths = quiz.map((step: QuizSteps, index: number) => ({
    params: { step: (index + 1).toString() },
  }))

  return {
    paths,
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps = async context => {
  const quiz: QuizSteps[] = quizData
  const params = context.params as Params
  const currentStep = parseInt(params.step)
  const previousStep = currentStep > 1 ? currentStep - 1 : null
  const nextStep = currentStep < quiz.length - 1 ? currentStep + 1 : null
  const step = quiz[currentStep - 1]
  const isLastStep = currentStep == quiz.length - 1

  return {
    props: {
      step,
      currentStep,
      previousStep,
      nextStep,
      isLastStep,
      stepsCount: quiz.length,
    },
  }
}

export default QuizPage

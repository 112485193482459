import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { clearQuizProgress } from "redux/quiz/steps";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Modal from "@components/Modal";
import ContinueButton from "@components/ContinueButton";

interface LayoutProps {
  children: React.ReactNode;
}

const Banner = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  return <>
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      modalClass="text-center"
      modalTitle={`GET 68% OFF!`}
    >
      <div className="mx-auto p-4 text-lg">
        <p className="mb-1">
          For a limited time, you can get 68% off your total order.
        </p>
        <p className="mb-4">
          Click here to apply the code automatically:
        </p>
        <ContinueButton
          enabled={true}
          text="APPLY PROMO CODE"
          size="large"
          extraClasses="shadow-pulse dark-button mx-auto leading-none"
          onClick={toggleModal}
        />
        <small>(Discount applied on checkout)</small>
      </div>
    </Modal>
    <div className="banner" onClick={toggleModal}>
      <div className="banner-close" onClick={toggleModal}>&times;</div>
      <div>Summer Sale 🎉 &mdash; Save up to 68%! <span className="underline hidden xs:inline ml-2">Info &rarr;</span></div>
    </div>
  </>
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const router = useRouter();
  const { asPath } = router;
  const pathArray = asPath.split('/');
  const pathClass = pathArray.map(p => {
    if (!isNaN(parseFloat(p))) {
      return `step-${p}`;
    } else {
      return p.replace(/\//g, "-");
    }
  }).join(' ');

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      dispatch(clearQuizProgress());
    });
  }, [dispatch, router.events])

  return <>
    <Banner />
    <div className={`layout ${pathClass}`}>
      <Header />
      <div className="items-center flex flex-col flex-1">
        <div className="layout-inner-1">
          <div className="layout-inner-2">
            <div className="w-full flex flex-col items-center justify-start md:pt-8">
              {children}
            </div>
          </div>
        </div>
        <Footer path={asPath} />
      </div>
    </div >
  </>;
}

export default Layout;

import { Router, useRouter } from "next/router";
import React from "react";

export const FacebookPixel = function () {
  const router = useRouter();

  React.useEffect(() => {
    import("@bettercart/react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string);
        ReactPixel.pageView();

        if (
          router.asPath === '/' ||
          router.asPath === '/quiz/1' ||
          router.asPath === '/quiz/start-female' ||
          router.asPath === '/quiz/start-male'
        ) {
          ReactPixel.track('ViewContent');
        }

        Router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, []);
  return null;
};

import classNames from "classnames";
import { FunctionComponent } from "react";

interface FooterProps {
  path: string;
}

const Footer: FunctionComponent<FooterProps> = ({ path }) => {
  const year = new Date().getFullYear();
  const isQuiz = path.includes("/quiz");

  if (path === '/quiz/1' || path === '/order/rx' || path === '/order/nutrition') {
    return <>
      <div className={classNames({
        "footer": true,
        "copy mt-4 text-sm text-gray-600 z-9 opacity-40 pb-4 text-center": true,
        "hidden md:block": isQuiz,
      })}>
        <p>
          &copy; {year} Measured, All Rights Reserved.
        </p>
        <p><a href="https://trymeasured.com/terms">Terms of Service</a> | <a href="https://trymeasured.com/privacy">Privacy Policy</a></p>
      </div>
    </>;
  } else {
    return <></>;
  }

}

export default Footer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuizProps } from "@types";
import { RootState } from "redux/store";

// Define a type for the slice state
type StepsState = {
  data?: QuizProps;
};

// Define the initial state using that type
const initialState: StepsState = {};

export const steps = createSlice({
  name: "quizSteps",
  initialState,
  reducers: {
    setQuizProgress: (state: StepsState, action: PayloadAction<QuizProps>) => {
      state.data = action.payload;
    },

    clearQuizProgress: () => initialState,
  },
});

export const { setQuizProgress, clearQuizProgress } = steps.actions;

const selectQuizSteps = (state: RootState): QuizProps | undefined =>
  state.quiz.steps.data;

export const selectPreviousStep = (
  state: RootState
): number | null | undefined => selectQuizSteps(state)?.previousStep;

export const selectProgressPercent = (state: RootState): number | null => {
  const quizSteps = selectQuizSteps(state);
  if (quizSteps?.currentStep && quizSteps?.stepsCount) {
    return Math.round(
      ((quizSteps.currentStep + 1) / quizSteps.stepsCount) * 100
    );
  }
  return null;
};

export const selectCurrentStep = (state: RootState): number | null =>
  state.quiz.steps?.currentStep;

export default steps.reducer;
